import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  HostListener,
  Output,
  EventEmitter,
  Inject, PLATFORM_ID
} from '@angular/core';
import { Location } from '@shared/location';
import { Order, OrderSummary } from '@shared/order';
import { DeliveryPrice } from '@shared/service';
import { OrderSummaryFacade } from './summary.facade';
import {ItemForm, ServiceItem} from "@shared/item";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'order-summary',
  templateUrl: 'summary.html',
  styleUrls: ['summary.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSummaryComponent {
  @Input() summary: OrderSummary;
  @Input() deliveryPrices: Array<DeliveryPrice>;
  @Input() order: Order;
  @Input() isLoading: boolean;
  @Input() isEditOrder: boolean;
  @Input() isAddToCard: boolean;
  @Input() removeItemsTemplate: TemplateRef<any>;
  @Input() location: Location;
  @Input() isAuthPage: boolean;
  @Input() stage: string;

  isOpened: boolean;

  @Output() updateItemsClicked: EventEmitter<Array<ItemForm>>;
  public get poundPrice(): number {
    return this.summary?.poundPrice || 0;
  }

  isMobile: boolean = false;
  ngOnInit() {
    if (isPlatformBrowser(this.platformID)) {
      this.isMobile = window.innerWidth < 767
    }
  }

  constructor(private facade: OrderSummaryFacade, @Inject(PLATFORM_ID) private platformID: object,) {
    this.updateItemsClicked = new EventEmitter();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (isPlatformBrowser(this.platformID)) {
      this.isMobile = window.innerWidth < 767
    }
  }

  public serviceFeeInfoClicked(): void {
    this.facade.openServiceFeeInfo();
  }

  public deliveryFeeInfoClicked(): void {
    this.facade.openDeliveryFeeInfo(this.deliveryPrices);
  }

  public increaseItemCountButtonClicked(item: ServiceItem,items:Array<ServiceItem>): void {
    let new_items =this.facade.addItemInArray(item,items)
    this.updateItemsClicked.emit(new_items)

  }
  public decreaseItemCountButtonClicked(item: ServiceItem,items:Array<ServiceItem>): void {
    let new_items =this.facade.removeItemFromArray(item,items)
    this.updateItemsClicked.emit(new_items)
  }
}
