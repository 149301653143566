import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { configuration } from '@configurations';
import { isPlatformBrowser } from '@angular/common';

@NgModule()
export class BrazeModule {
  constructor(
    @Inject(PLATFORM_ID) private platformID: object
  ) {
    if (configuration.environment === 'production') {
      if (isPlatformBrowser(this.platformID)) {
        import(
          "@braze/web-sdk"
          ).then(({ initialize, automaticallyShowInAppMessages, requestPushPermission }) => {
          initialize(configuration.brazeKey, {
            baseUrl: configuration.brazeUrl,
            enableLogging: true,
            allowUserSuppliedJavascript: true
          });
          automaticallyShowInAppMessages();
          requestPushPermission();
        });
      }
    }
  }
}
