<div style="position:relative;">
<div class="item-image">
  <div class="item-image-content skeleton">
    <img *ngIf="item?.imageLink" placeholderVariant="default-small" [src]="item?.imageLink" alt="Hamperapp item">
  </div>
  <div *ngIf="item?.count > 1 && !isAddToCard" class="item-image-count">
    {{ item.count }}
  </div>

</div>
<div class="item-count-change" *ngIf="isAddToCard" >
  <order-items-item-controls  [count]="item.count"  (decreased)="decreaseCountButtonClicked()"
                              (increased)="increaseCountButtonClicked()">
  </order-items-item-controls>
</div>
</div>
