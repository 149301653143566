<div [class.loading]="isLoading" [class.summary-canceled]="order?.isCanceled"
  class="summary hidden-scroll loading-primary">
  <ng-container *ngVar="'SHARED.ORDER_SUMMARY.' as translations">
    <location-mismatch *ngIf="location" [location]="location" class="hidden-mobile"></location-mismatch>
    <div class="summary-title">
      {{ translations + "TEXT_SUMMARY" | translate }}
      <show-button-simple *ngIf="isAuthPage" [isOpened]="isOpened" (clicked)="isOpened = !isOpened" ></show-button-simple>
    </div>
    <div class="summary-body" *ngIf="isOpened || !isAuthPage|| !isMobile ">

      <ng-container *ngIf="!!summary?.details?.pounds">
        <div class="property property-regular summary-pounds">
          <div class="property-name">
            {{ translations + 'TEXT_ONE_TIME_ORDER' | translate: { count: summary.details.poundsCount } }}
          </div>
          <div class="property-value">
            <summary-price [price]="summary.details?.pounds"></summary-price>
          </div>
          <info-field iconType="logo" iconBackground="primary" [underline]="false"
            [title]="translations + 'TEXT_WEIGHT_INFO' | translate: { weight: summary.details.poundsCount, service: order?.laundryService | serviceName }"
            [info]="translations + 'TEXT_PER_POUND' | translate: { price: (poundPrice | currency) }">
          </info-field>
        </div>
      </ng-container>
      <div *ngIf="order?.hasPreferences && summary?.details && stage!=='items'" class="property property-regular">
        <div class="property-name">
          {{ translations + 'TEXT_PREFERENCES' | translate }}
          <br />
          <div class="summary-options-list">
            {{ order.options | optionNames }}
          </div>
        </div>
        <div class="property-value summary-options-price">
          <summary-price [price]="summary.details.options"></summary-price>
        </div>
      </div>
      <ng-container *ngIf="order?.hasItems && summary?.details">
        <div class="property property-regular items">
          <div class="property-name">
            {{ translations + (summary.details.poundsCount ? 'TEXT_ADDITIONAL_ITEMS' : 'TEXT_ITEMS') | translate:
            {
            count: order.items | itemsCount
            }
            }}
          </div>
          <div class="property-value">
            <summary-price [price]="summary.details.items"></summary-price>
          </div>
          <div class="summary-items">
            <item-image *ngFor="let item of order.items; trackByProperty: 'id'" [item]="item"
              class="summary-items-item"
              (countDecreased)="decreaseItemCountButtonClicked(item,order.items)"
              (countIncreased)="increaseItemCountButtonClicked(item,order.items)"
               [isAddToCard]="isAddToCard"
            >
            </item-image>
<!--            <div *ngIf="removeItemsTemplate" class="summary-items-remove">-->
<!--              <ng-container [ngTemplateOutlet]="removeItemsTemplate">-->
<!--              </ng-container>-->
<!--            </div>-->
          </div>
        </div>
      </ng-container>


      <div *ngIf="summary && stage!=='items'" class="property property-regular">
        <div class="property-name">
          {{ translations + 'TEXT_SUBTOTAL' | translate }}

          <span *ngIf="isEditOrder"
            tooltip="The Minimum Order, any amount exceeding the requirement, and charged preferences are included in the subtotal."
            [showDelay]="0" [hideDelay]="0" placement="right" maxWidth="306px" class="summary-information">
            <icon type="information-outline"></icon>
          </span>
          <ng-container *ngIf="!order.id">
            <span
              tooltip="The Minimum Order, any amount exceeding the requirement, and charged preferences are included in the subtotal."
              [showDelay]="0" [hideDelay]="0" placement="right" maxWidth="306px" class="summary-information">
              <icon type="information-outline"></icon>
            </span>
          </ng-container>
        </div>
        <div class="property-value">
          <summary-price [price]="summary.subTotal + summary.fees?.priceRemainings"></summary-price>
        </div>
      </div>

      <div *ngIf="summary?.fees && stage!=='items'&& stage!=='preferences'" class="property property-regular">
        <div class="property-name">
          {{ translations + 'TEXT_SERVICE_FEE' | translate }}

          <span class="summary-information">
            <app-button [isFlat]="true" (clicked)="serviceFeeInfoClicked()">
              <icon type="information-outline"></icon>
            </app-button>
          </span>
        </div>
        <div class="property-value">
          <summary-price [price]="summary.fees.service"></summary-price>
        </div>
      </div>
      <div *ngIf="summary?.fees && order?.laundryService?.hasDeliveryFee && stage!=='items'&& stage!=='preferences'" class="property property-regular">
        <div class="property-name">
          {{ translations + 'TEXT_DELIVERY_FEE' | translate }}
          <span class="summary-information">
            <app-button [isFlat]="true" (clicked)="deliveryFeeInfoClicked()">
              <icon type="information-outline"></icon>
            </app-button>
          </span>
        </div>
        <div class="property-value">
          <summary-price [price]="summary.fees.delivery"></summary-price>
        </div>
      </div>

      <div *ngIf="summary?.tip && stage!=='items'" class="property property-regular">
        <div class="property-name">
          {{ translations + 'TEXT_DRIVER_TIP' | translate }}
        </div>
        <div class="property-value">
          <summary-price [price]="summary.tip"></summary-price>
        </div>
      </div>

      <div *ngIf="summary?.discount?.amount && stage!=='items'" class="property property-regular">
        <div class="property-name">
          {{ translations + 'TEXT_DISCOUNT' | translate: { discountType: summary.discount.type } }}
        </div>
        <div class="property-value summary-discount">
          <summary-price [price]="-summary.discount.amount"></summary-price>
        </div>
      </div>

      <div *ngIf="order?.cancellationFee" class="property summary-cancellation">
        <div class="property-name">
          {{ translations + 'TEXT_CANCELLATION_FEE' | translate }}
        </div>
        <div class="property-value">
          <summary-price [price]="order?.cancellationFee"></summary-price>
        </div>
      </div>

    </div>
    <div *ngIf="summary " class="summary-footer">
      <div class="property summary-total">
        <ng-container *ngIf="order?.paid; else notPaid">
          <div class="property-name">
            {{ translations + 'TEXT_PAID' | translate: { method: (order.paymentMethod | paymentMethodName) } }}
          </div>
          <div class="property-value">
            <summary-price [price]="order.paid"></summary-price>
          </div>
        </ng-container>
        <ng-template #notPaid>
          <ng-container *ngIf="stage==='items'">

          <div class="property-name">
            {{ translations + 'TEXT_SUBTOTAL' | translate }}
            <span
                  tooltip="The Minimum Order, any amount exceeding the requirement, and charged preferences are included in the subtotal."
                  [showDelay]="0" [hideDelay]="0" placement="right" maxWidth="306px" class="summary-information">
            <icon type="information-outline"></icon>
          </span>
          </div>
          <div class="property-value">
            <summary-price [price]="summary.subTotal + summary.fees?.priceRemainings"></summary-price>
          </div>
          </ng-container>
          <ng-container *ngIf="stage!=='items'">
          <div class="property-name">
            <span>{{ translations + 'TEXT_TOTAL_AMOUNT' | translate }}</span>
            <span *ngIf="!order?.isCanceled" [tooltip]="translations + 'TEXT_TOTAL_INFO' | translate" [showDelay]="0"
              [hideDelay]="0" placement="right" maxWidth="306px" class="summary-information">
              <icon type="information-outline"></icon>
            </span>
          </div>
          <div class="property-value">
            <summary-price [price]="summary.total"></summary-price>
          </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <div class="next-button-summary">
    <ng-content></ng-content>
    </div>
  </ng-container>
</div>
