import { CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BaseFormControlDirective } from '@shared/base-form-control';
import { FormControlCounterComponent } from '@shared/form-control-counter';
import { NgVariableDirective } from '@shared/ng-variable';
import {ButtonComponent} from "@shared/button";

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    CurrencyPipe,
    TranslateModule,
    NgVariableDirective,
    FormControlCounterComponent,
    FormsModule,
    ButtonComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PoundsSelectorComponent),
      multi: true
    }
  ],
  selector: 'pounds-selector',
  templateUrl: 'pounds-selector.html',
  styleUrls: ['pounds-selector.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoundsSelectorComponent extends BaseFormControlDirective<number> {
  @Input() minPounds: number;
  @Input() poundPrice: number;
  @Input() isNewMode: boolean=false;

}
