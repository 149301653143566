import {inject, Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {FooterLink} from './models';
import {Zip} from "@shared/zip";
import {UserZipSelectors} from "@shared/user";
import {Store} from "@ngrx/store";
import {AppState} from "@shared/store";
import {LocationService} from "@shared/location";
import {ApiService} from "@shared/api";
import {TopLocationsActions, TopLocationSelectors} from "@shared/footer/store";
import {Location} from "@shared/location/models/location";
import {AuthService} from "@shared/auth";

@Injectable()
export class FooterFacade {
  public get citiesInLoundryService(): Array<FooterLink> {
    return [

      new FooterLink({
        text: "Miami, FL",
        href: "/services/city/miami-fl"
      }),
      new FooterLink({
        text: "Miami Beach, FL",
        href: "/services/city/miami-beach-fl"
      }),
      new FooterLink({
        text: "Pompano Beach, FL",
        href: "/services/city/pompano-beach-fl"
      }),
      new FooterLink({
        text: "Houston, TX",
        href: "/services/city/houston-tx"
      }),
      new FooterLink({
        text: "Washington, D.C",
        href: "/services/city/washington-dc"
      }),
      new FooterLink({
        text: "Chicago, IL",
        href: "/services/city/chicago-il"
      }),
      new FooterLink({
        text: "New York, NY",
        href: "/services/city/new-york-ny"
      }),
      new FooterLink({
        text: "Dallas, TX",
        href: "/services/city/dallas-tx"
      }),
      new FooterLink({
        text: "Orlando, FL",
        href: "/services/city/orlando-fl"
      }),
      new FooterLink({
        text: "Las Vegas, NV",
        href: "/services/city/las-vegas-nv"
      }),
      new FooterLink({
        text: "Sacramento, CA",
        href: "/services/city/sacramento-ca"
      }),
      new FooterLink({
        text: "San Jose, CA",
        href: "/services/city/san-jose-ca"
      }),
      new FooterLink({
        text: "Los Angeles, CA",
        href: "/services/city/los-angeles-ca"
      }),
      new FooterLink({
        text: "Santa Clara, CA",
        href: "/services/city/santa-clara-ca"
      }),
      new FooterLink({
        text: "Tampa, FL",
        href: "/services/city/tampa-fl"
      }),
      new FooterLink({
        text: "Austin, TX",
        href: "/services/city/austin-tx"
      }),
      new FooterLink({
        text: "Jacksonville, FL",
        href: "/services/city/jacksonville-fl"
      }),
      new FooterLink({
        text: "Clearwater, FL",
        href: "/services/city/clearwater-fl"
      }),
      new FooterLink({
        text: "Boca Raton, FL",
        href: "/services/city/boca-raton-fl"
      }),
      new FooterLink({
        text: "Boynton Beach, FL",
        href: "/services/city/boynton-beach-fl"
      }),
      new FooterLink({
        text: "Atlanta, GA",
        href: "/services/city/atlanta-ga"
      }),
      new FooterLink({
        text: "San Francisco, CA",
        href: "/services/city/san-francisco-ca"
      }),
      new FooterLink({
        text: "San Diego, CA",
        href: "/services/city/san-diego-ca"
      }),
      new FooterLink({
        text: "Seattle, WA",
        href: "/services/city/seattle-wa"
      }),
      new FooterLink({
        text: "Fort Lauderdale, FL",
        href: "/services/city/fort-lauderdale-fl"
      }),
      new FooterLink({
        text: "Hollywood, FL",
        href: "/services/city/hollywood-fl"
      }),
      new FooterLink({
        text: "Arlington, TX",
        href: "/services/city/arlington-tx"
      }),
      new FooterLink({
        text: "Irving, TX",
        href: "/services/city/irving-tx"
      }),
      new FooterLink({
        text: "Chula Vista, CA",
        href: "/services/city/chula-vista-ca"
      }),
      new FooterLink({
        text: "West Palm Beach, FL",
        href: "/services/city/west-palm-beach-fl"
      }),
      new FooterLink({
        text: "Saint Petersburg, FL",
        href: "/services/city/saint-petersburg-fl"
      }),
      new FooterLink({
        text: "Fort Worth, TX",
        href: "/services/city/fort-worth-tx"
      }),

    ];
  }

  protected store: Store<AppState>;



  public get services(): Array<FooterLink> {
    return [
      new FooterLink({text: 'Laundromat near me', href: '/laundromat-near-me',isCityAdd:false}),
      new FooterLink({text: 'Laundry near me', href: '/laundromat-near-me',isCityAdd:false}),
      new FooterLink({text: 'Dry cleaners near me', href: '/dry-cleaners-near-me',isCityAdd:false}),
      new FooterLink({text: 'Laundry service near me', href: '/laundry-service-near-me',isCityAdd:false}),
      new FooterLink({text: 'Wash and fold laundry service near me', href: '/laundry-service-near-me',isCityAdd:false}),
      new FooterLink({text: 'Laundry pickup service near me', href: '/laundry-service-near-me',isCityAdd:false}),
      new FooterLink({text: 'Dry cleaning service', href: '/services/dry-cleaning-services',isCityAdd:true}),
      new FooterLink({text: 'Dry cleaner service', href: '/services/dry-cleaning-services',isCityAdd:true}),
      new FooterLink({text: 'Laundry service', href: '/services/wash-and-fold-laundry-services',isCityAdd:true}),
      new FooterLink({text: 'Wash and fold laundry service', href: '/services/wash-and-fold-laundry-services',isCityAdd:true}),
      new FooterLink({text: 'Laundry delivery service', href: '/services/wash-and-fold-laundry-services',isCityAdd:true}),
      new FooterLink({text: 'Laundry pick up service', href: '/services/wash-and-fold-laundry-services',isCityAdd:true}),
      new FooterLink({text: 'Commercial laundry service', href: '/commercial',isCityAdd:true,addUrl:'/laundry-services'}),
      new FooterLink({text: 'Commercial laundry service near me', href: '/commercial',isCityAdd:true,addUrl:'/laundry-services'}),
      new FooterLink({text: 'Commercial linen service', href: '/commercial',isCityAdd:true,addUrl:'/laundry-services'}),
      new FooterLink({text: 'Commercial linen service near me', href: '/commercial',isCityAdd:true,addUrl:'/laundry-services'}),


    ];
  }

  public get additionalContentTemplate$(): Observable<TemplateRef<any>> {
    return this.additionalContentTemplateSubject$.asObservable();
  }

  private additionalContentTemplateSubject$: Subject<TemplateRef<any>>;

  constructor(private locationService: LocationService, private apiService: ApiService,private authService: AuthService,) {
    this.additionalContentTemplateSubject$ = new BehaviorSubject(null);
    this.store = inject(Store);
  }

  public setAdditionalContentTemplate(content: TemplateRef<any> | null): void {
    this.additionalContentTemplateSubject$.next(content);
  }

  public get userZip$(): Observable<Zip> {
    return this.store.select(UserZipSelectors.zip);
  }

  public get topLocations$(): Observable<Array<Location>> {
    return this.store.select(TopLocationSelectors.getCities);
  }

  public loadTopLocations(): void {
    this.store.dispatch(TopLocationsActions.loadLocations());
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }
}
